import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, withLatestFrom } from 'rxjs';
import { UserService } from './services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  readonly isLogined$ = this.userService.getUser().pipe(map(Boolean));

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userService: UserService
  ) {
    this.initAnalytics();
  }

  private initAnalytics() {
    if (!environment.production) {
      return;
    }

    const ym = (window as any).ym;

    ym(92334933, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    });
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        withLatestFrom(this.userService.getUser())
      )
      .subscribe(([_, user]) => {
        const userTgId = user?.id;

        ym(92334933, 'hit', {
          url: window.location.href,
          title: this.route.snapshot.data['sheetName'],
          ctx: {
            userTgId,
          },
        });
      });
  }
}
