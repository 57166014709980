import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();

  try {
    Sentry.init({
      dsn: 'https://1704b4ebcddc49d19065312f3edca2db@o4504980113391616.ingest.sentry.io/4504980114833408',
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: ['localhost', `${environment.apiUrl}/api`],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  } catch (e) {}
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
