import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Item } from '../models/item';
import { ProductPhoto } from '../models/product-photo';
import { User } from '../models/user';
import { WbField } from '../models/wb-fields';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private readonly http: HttpClient) {}

  getAllSheetItems(
    sheetName: string,
    authToken: string = ''
  ): Observable<Item[]> {
    return this.http
      .get<Item[]>((environment['apiUrl'] || '') + '/api/sheet-items', {
        params: {
          sheet: sheetName,
          authToken,
        },
      })
      .pipe(
        map((data) => {
          return data.map((item) => ({
            ...item,
            [WbField.imtId]: Number(item[WbField.imtId]),
            [WbField.detailSalePriceU]: Number(item[WbField.detailSalePriceU]),
            [WbField.nmId]: Number(item[WbField.nmId]),
            [WbField.messageDate]: +this.parseDate(item[WbField.messageDate]),
            [WbField.messageRepeatCount]: Number(
              item[WbField.messageRepeatCount]
            ),
          }));
        })
      );
  }

  getProductPhotos(
    sheetName: string,
    productId: number,
    authToken: string = ''
  ): Observable<string[]> {
    return this.http.get<string[]>(
      (environment['apiUrl'] || '') + '/api/product-photos',
      {
        params: {
          sheet: sheetName,
          productId,
          authToken,
        },
      }
    );
  }

  getProductsPhotos(
    sheetName: string,
    authToken: string = ''
  ): Observable<ProductPhoto[]> {
    return this.http.get<ProductPhoto[]>(
      (environment['apiUrl'] || '') + '/api/products-photos',
      {
        params: {
          sheet: sheetName,
          authToken,
        },
      }
    );
  }

  getUser(authToken: string): Observable<User> {
    return this.http.get<User>((environment['apiUrl'] || '') + '/api/verify', {
      params: {
        authToken,
      },
    });
  }

  logout(authToken: string) {
    return this.http.get((environment['apiUrl'] || '') + '/api/logout', {
      params: {
        authToken,
      },
    });
  }

  private parseDate(str?: string | number | null) {
    if (!str) {
      return new Date();
    }

    if (typeof str === 'number') {
      return new Date(str);
    }

    const parts = str.split(/\/|\./);

    if (parts.length < 3) {
      return new Date(str);
    }

    const date = new Date(
      parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10)
    );

    return date;
  }
}
