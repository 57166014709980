import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { nanoid } from 'nanoid';
import { Subject } from 'rxjs';
import { retry } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent {
  readonly userLoading$ = new Subject<boolean>();

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly apiService: ApiService
  ) {}

  onSignIn() {
    const authToken = nanoid();
    const user: User = {
      authToken,
    } as User;

    window.localStorage.setItem('user', JSON.stringify(user));
    this.userLoading$.next(true);

    window.open(
      `tg://resolve?domain=${environment.authTgBot}&start=authToken-${authToken}`,
      '_blank'
    );
    this.apiService
      .getUser(authToken)
      .pipe(retry({ count: 10, delay: 5000 }))
      .subscribe((user) => {
        this.userService.setUser(user);
        this.router.navigate(['/']);
      });
  }
}
