export const environment = {
  production: true,
  apiUrl: '/demo',
  womanSheet: 'woman',
  manSheet: 'male',
  wbOtherSheet: 'other',
  wbProductsBucket: 'demo',
  msgImgBucket: 'demo',
  authTgBot: 'wildberries_sheet_bot',
  isDemo: true,
};
